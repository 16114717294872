<template>
  <div
    id="app"
    ref="appContainer"
    v-cloak
    :style="keyboardHeight ? 'overflow: hidden' : null"
  >
    <WelcomePage
      :displayIntroVideo="displayIntroVideo"
      v-if="showIntroVideo"
      @closeIntroVideo="showIntroVideo = false"
    />
    <fullscreen
      id="fullscreen"
      ref="fullscreen"
      @change="callFullscreen"
      class="h-full w-screen overflow-hidden fixed flex flex-col lg:flex-row"
    >
      <TopBar
        ref="topBar"
        :isInFullscreen="isInFullscreen"
        :isInGame="isInGame"
        :isSearchPopUpOpen="isSearchPopUpOpen"
        :keyboardHeight="keyboardHeight"
      />
      <Menu
        :deferredPrompt="deferredPrompt"
        v-if="$store.getters['auth/getIsAuthenticated']"
      />
      <div
        id="main-container"
        :class="{
          'main-container__fullscreen-mob-por main-container__fullscreen-mob-land main-container__fullscreen-tab':
            isInFullscreen,
        }"
      >
        <!-- @regionPopUpVisibility="regionPopUpVisibilityHandler" -->
        <router-view
          @isInGameUpdate="isInGameUpdateHandler"
          @searchPopUpVisibility="searchPopUpVisibilityHandler"
          @fullscreenBtnVisibility="fullscreenBtnVisibilityHandler"
          :hideFullscreenBtn="!showFullscreenBtn || isSearchPopUpOpen"
          :isInFullscreen="isInFullscreen"
        />
      </div>
      <PWAiOs
        v-if="isIos && showPWAModal && !isStandalone && isAuthenticated"
        @closePWAModal="closePWAModalHandler"
      />
      <PWAAndroid
        v-if="
          isAndroid &&
          deferredPrompt &&
          !isStandalone &&
          showPWAModal &&
          isAuthenticated
        "
        :deferredPrompt="deferredPrompt"
        @closePWAModal="closePWAModalHandler"
      />
      <DailyRewardModal
        v-if="rewardEventData"
        :data="rewardEventData"
        :hideX="true"
        @close="closeDailyReward"
      />
      <Toasts />
      <!-- <audio id="soundButton" ref="soundButton" controls preload="auto">
        <source src="/sound/button_click.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio> -->
    </fullscreen>
  </div>
</template>

<script>
import "./assets/css/tailwind.css";
import TopBar from "@/components/TopBar";
import UserInfo from "@/components/UserInfo";
import userRepository from "@/api/userRepository";
import settingsRepository from "@/api/settingsRepository";
import tokensRepository from "@/api/tokensRepository";
import { getCredit } from "@/api/creditRepository";
import { login } from "@/api/authRepository";
import PWAiOs from "./components/PWAiOs.vue";
import PWAAndroid from "./components/PWAAndroid.vue";
import WelcomePage from "./components/WelcomePage.vue";
import analyticsConfig from "./mixins/analyticsConfig.vue";
import Menu from "@/components/Menu";
import DailyRewardModal from "@/components/DailyRewardModal.vue";
import Toasts from "@/components/utils/Toasts";
import { getSkins } from "@/api/geralRepository";

import { mapState, mapGetters } from "vuex";

export default {
  name: "App",

  mixins: [analyticsConfig],

  data() {
    return {
      mapReady: false,
      apiReady: false,
      // isCollapsed: true,
      userId: this.$store.getters["auth/getUserId"],
      deferredPrompt: null,
      showPWAModal: this.$store.getters["appSettings/getShowPWA"],
      showIntroVideo: true,
      isInFullscreen:
        document.webkitFullscreenElement || document.fullscreenElement
          ? true
          : false,
      isInGame: false,
      displayIntroVideo: this.$store.getters["getDisplayIntroVideo"],
      isSearchPopUpOpen: false,
      showFullscreenBtn: true,
      keyboardHeight: 0,
      currentLocation: "MAP",
      //TODO passar isto pa store - isto aka skins
      skins: null,
    };
  },

  components: {
    TopBar,
    UserInfo,
    PWAiOs,
    PWAAndroid,
    WelcomePage,
    Menu,
    DailyRewardModal,
    Toasts,
  },

  computed: {
    ...mapState("deviceInfo", ["isIos", "isStandalone", "isAndroid"]),
    ...mapState("socket", ["rewardEventData"]),
    ...mapGetters("deviceInfo", ["isPWAInIphone"]),

    areManagerPages() {
      return this.$route.path.indexOf("manager") !== -1;
    },
    orientation() {
      return this.$store.getters["fullscreen/getOrientation"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/getIsAuthenticated"];
    },
    username() {
      return this.$store.getters["userInfo/getNickname"];
    },
    roomName() {
      return null;
    },
  },

  async created() {
    window.addEventListener("fullscreenchange", this.checkFullscreen);
    window.addEventListener("webkitfullscreenchange", this.checkFullscreen);
    this.$store.dispatch("deviceInfo/setDeviceInfo");

    //TODO Add this to mixin
    const params = new URLSearchParams(window.location.search);
    const query = params.get("auth");
    if (query) {
      let q = JSON.parse(atob(query));
      this.setUserConfig(q);
    } else {
      //TODO Add this to mixin
      const token = params.get("token");
      const contractNumber = params.get("contractNumber");
      let skinId = params.get("skinId");

      if (
        this.$store.getters["auth/getIsAuthenticated"] == true &&
        !!token === false &&
        !!contractNumber === false &&
        !!skinId === false
      ) {
        let promises = [
          //TODO response.data.userId
          userRepository.getUser(this.userId),
          //TODO response.data.userId
          settingsRepository.getSettings(this.userId),
          //TODO response.data.userId
          getCredit(this.userId),
          tokensRepository.getCredit(this.userId),
          userRepository.getAvatar(this.userId),
        ];

        Promise.all(promises)
          .then(([user, settings, credit, tokens, avatar]) => {
            this.$store.commit("userInfo/mutateUser", user.data);
            this.$store.commit("userInfo/mutateSettings", settings.data);
            this.$store.commit("userInfo/mutateCredit", credit.data);
            this.$store.commit("userInfo/mutateTokens", tokens.data);
            this.$store.commit(
              "userInfo/mutateAvatarCustom",
              avatar.data && avatar.data.photo ? avatar.data.photo : null
            );

            ////////////////////////////////////////////////////////////
            this.userIdAnalyticsConfig(user.data.nickname);
            ////////////////////////////////////////////////////////////

            // if (this.$route.name !== "SlotsBar") {

            //   console.log("this.$router.name", this.$route.name);
            // }
            // const hasCustomBar = window.location.pathname.includes("/bar/");
            // if (!hasCustomBar) {
            //   if (this.$store.getters["userInfo/getGoToBar"] == true) {s
            //     let defaultBar = this.$store.getters["userInfo/getDefaultBar"];
            //     this.$router.push({
            //       name: "Bar",
            //       params: { barName: defaultBar },
            //     });
            //   } else {
            //     this.$router.push({ name: "SlotsBar" });
            //   }
            // }
            try {
              if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                this.$store.dispatch("socket/init");
              }
            } catch (err) {
              console.error(err);
            }
          })
          .catch((e) => {
            // TODO implement refresh token logic
            this.$store.dispatch("auth/cleanUpAuth");
            this.$store.dispatch("socket/destroySocket");
            this.$router.push({ name: "Login" });
          });
      }

      if (
        this.$store.getters["auth/getIsAuthenticated"] == true &&
        this.$route.name !== "Auth"
      ) {
        userRepository.trackVisit(this.$store.state.auth.userId).then(() => {
          console.log("tracked visit");
        });
      }

      getSkins()
        .then((res) => {
          this.skins = res.data;
          const sessionId = params.get("sessionId");
          const skin = params.get("skin");
          const returnUrl = params.get("return_url");

          skinId = this.skins.find((s) => s.name == skin).id_skin;

          const obj = { sessionId, skinId };

          loginFromSkinWithCredentials(obj)
            .then((res) => {
              if (res.status == 202) {
                console.log("vou p o auth polling");
                this.authPolling(this.email, this.password);
                return;
              }

              const auth = {
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
                deviceId: res.data.deviceId,
                userId: res.data.userId,
                externalUserId: res.data.externalUserId,
                numContract: res.data.numContract,
                sessionId: res.data.sessionId,
                isAuthenticated: true,
              };

              this.$store.dispatch("auth/setUpAuth", auth);

              let promises = [
                userRepository.getUser(res.data.userId),
                settingsRepository.getSettings(res.data.userId),
                getCredit(res.data.userId),
                tokensRepository.getCredit(res.data.userId),
                userRepository.getAvatar(res.data.userId),
              ];

              Promise.all(promises).then(
                ([user, settings, credit, tokens, avatar]) => {
                  user.data, settings.data, credit.data;

                  // Update userInfo in store
                  this.$store.commit("userInfo/mutateUser", user.data);
                  this.$store.commit("userInfo/mutateSettings", settings.data);
                  this.$store.commit("userInfo/mutateCredit", credit.data);
                  this.$store.commit(
                    "userInfo/mutateAvatarCustom",
                    avatar.data && avatar.data.photo ? avatar.data.photo : null
                  );
                  this.$store.commit("userInfo/mutateTokens", tokens.data);

                  try {
                    if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                      this.$store.dispatch("socket/init");
                    }
                  } catch (err) {
                    console.error(err);
                  }

                  if (this.nextUrl) {
                    this.$router.push({ path: `${this.nextUrl}` });
                  } else {
                    if (this.$store.getters["userInfo/getGoToBar"] == true) {
                      let defaultBar =
                        this.$store.getters["userInfo/getDefaultBar"];
                      this.$router.push({
                        name: "Bar",
                        params: { barName: defaultBar },
                      });
                    } else {
                      this.$router.push({ name: "SlotsBar" });
                    }
                  }
                }
              );
            })
            .catch((err) => {
              this.loading = false;

              if (err.response && err.response.status == 418) {
                this.skinId = Number(this.skins[0].id_skin);
                this.showModalSkin = true;
              }

              if (
                err.response &&
                err.response.status == 302 &&
                !!err.response.data.redirectUrl
              ) {
                window.location.href = err.response.data.redirectUrl;
              } else {
                this.hasErrors.push("Qualcosa è andato storto.");
              }
            });
        })
        .catch(() => console.error("couldnt get skins"));
    }

    /*
    **************

    ROOT LISTENERS

    **************
    */

    this.$root.$on("toggled", this.toggledHandler);
    this.$root.$on(
      "fullscreenBtnVisibility",
      this.fullscreenBtnVisibilityHandler
    );
    this.$root.$on("ready", (event) => {
      this.mapReady = true;
    });
    this.$root.$on("showPWAModal", () => (this.showPWAModal = true));
    // this.$root.$on("keyboardIsOpen", this.visualViewportResizeHandler);
  },

  mounted() {
    this.fixViewportDimensions();
    window.addEventListener("resize", this.fixViewportDimensions);
    window.visualViewport.addEventListener("resize", (event) =>
      this.visualViewportResizeHandler(event.target.height)
    );

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // set localstorage
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      // TODO clean cookies backup
      this.$store.dispatch("appSettings/updateShowPwa", false);
      this.deferredPrompt = null;
    });
  },

  methods: {
    closeDailyReward() {
      this.$store.commit("socket/clearRewardEventData");
    },

    visualViewportResizeHandler(viewportHeight) {
      if (viewportHeight < window.innerHeight) {
        this.keyboardHeight = window.innerHeight - viewportHeight;
      } else {
        this.keyboardHeight = 0;
      }
    },

    fullscreenBtnVisibilityHandler(val) {
      this.showFullscreenBtn = val;
    },

    searchPopUpVisibilityHandler(val) {
      this.isSearchPopUpOpen = val;
    },

    isInGameUpdateHandler(val) {
      this.isInGame = val;
    },

    checkFullscreen() {
      this.isInFullscreen =
        document.webkitFullscreenElement || document.fullscreenElement
          ? true
          : false;
    },

    // TODO simplify this repeated functionm with login.vue
    loginUser(email, password) {
      login({ loginField: email, password })
        .then((response) => {
          this.setUserConfig(response.data);
        })
        .catch((err) => {
          if (err.response.status == 401 || err.response.status == 400) {
            this.hasErrors.push("Email e/o password errate.");
          }
        });
    },

    fixViewportDimensions() {
      let vh = document.documentElement.clientHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },

    callFullscreen() {
      this.$store.dispatch("fullscreen/updateFullscreen");
    },

    toggledHandler() {
      this.$refs["fullscreen"].toggle();
    },

    closePWAModalHandler() {
      this.showPWAModal = false;
    },

    setUserConfig(data) {
      this.$store.dispatch("auth/updateAccessToken", data.accessToken);
      this.$store.dispatch("auth/updateRefreshToken", data.refreshToken);
      this.$store.dispatch("auth/updateDeviceId", data.deviceId);
      this.$store.dispatch("auth/updateUserId", data.userId);
      this.$store.dispatch("auth/updateIsAuthenticated", true);
      this.$store.dispatch("auth/updateExternalUserId", data.externalUserId);
      this.$store.commit("auth/mutateNumContract", data.numContract);

      let promises = [
        // TODO passar accesstoken
        userRepository.getUser(data.userId),
        settingsRepository.getSettings(data.userId),
        getCredit(data.userId),
        tokensRepository.getCredit(data.userId),
        userRepository.getAvatar(data.userId),
      ];
      Promise.all(promises).then(([user, settings, credit, tokens, avatar]) => {
        user.data, settings.data, credit.data;
        this.$store.commit("userInfo/mutateUser", user.data);
        this.$store.commit("userInfo/mutateSettings", settings.data);
        this.$store.commit("userInfo/mutateCredit", credit.data);
        this.$store.commit("userInfo/mutateTokens", tokens.data);

        this.$store.commit(
          "userInfo/mutateAvatarCustom",
          avatar.data && avatar.data.photo ? avatar.data.photo : null
        );

        ////////////////////////////////////////////////////////////
        this.userIdAnalyticsConfig(user.data.nickname);
        ////////////////////////////////////////////////////////////

        if (this.nextUrl) {
          this.$router.push({ path: `${this.nextUrl}` });
        } else {
          if (this.$store.getters["userInfo/getGoToBar"] == true) {
            let defaultBar = this.$store.getters["userInfo/getDefaultBar"];
            this.$router.push({
              name: "Bar",
              params: { barName: defaultBar },
            });
          } else {
            this.$router.push({ name: "SlotsBar" });
          }
        }
      });
    },
  },

  updated() {
    /* TODO  MAKE SURE TO REMOVE ITTTTTT, DONT FORGET */
    /* ADD AUDIO TO APP THAT IS HIDDEN */
    /* TRIGGER THAT AUDIO, PEGA */
    /* TODO - AUDIO CLICK */
    // const btns = document.getElementsByTagName("button");
    // const links = document.getElementsByTagName("a");
    // for (const btn of btns) {
    //   btn.addEventListener("click", () => {
    //     this.$refs.soundButton.play();
    //   });
    //   // btn.addEventListeners("mouseout", () => {
    //   // });
    // }
    // for (const link of links) {
    //   link.addEventListener("click", () => {
    //     this.$refs.soundButton.play();
    //   });
    //   // btn.addEventListeners("mouseout", () => {
    //   // });
    // }
    // const map = document.getElementById("chartdiv");
    // if (map) {
    //   map.addEventListener("click", () => {
    //     this.$refs.soundButton.play();
    //   });
    // }
  },

  beforeDestroy() {
    localStorage.removeItem("VR_OldHeights");
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="postcss">
[v-cloak] {
  display: none;
}
/* html {
    height: 100vh;
    @apply bg-gray-800 overflow-hidden;
  } */
/* 
   html,
   body {
         margin: 0;
         padding: 0;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
      @apply relative h-full w-full box-border;
   } */

html,
body {
  /* overflow: hidden; */
  @apply h-screen;
}

#app {
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(38, 38, 38);
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  -moz-touch-action: manipulation;
  -webkit-touch-action: manipulation;
  /* min-height: -webkit-fill-available; */

  @apply h-full bg-gray-800 relative overflow-y-auto box-border fixed;
}

#fullscreen {
  background-color: #222222;
  background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23191919' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
  /* background: url("/img/beteslot.png"); */
  /* background: url("/img/cirsa.png"); */
  background-size: 300px 300px;
  background-repeat: repeat;

  &:fullscreen,
  &::backdrop {
    @apply h-screen;
  }

  #menu-button {
    left: 83%;
    @apply z-50 bottom-0 fixed p-2 bg-gray-800 shadow-1 rounded-t-xl gap-1;

    svg {
      @apply transform -rotate-90 mx-auto;

      &.is-menu-open {
        @apply transform -rotate-90 -scale-x-1 duration-300;
      }
    }

    @screen lg {
      bottom: 70%;
      left: 0;
      @apply absolute transform transition-all duration-300 rounded-t-none rounded-r-xl;

      &.is-menu-open {
        @apply translate-x-553.8;
      }

      svg {
        @apply transform rotate-0;

        &.is-menu-open {
          @apply transform -scale-x-1;
        }
      }
    }
  }

  #main-container {
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -webkit-overflow-scrolling: touch;
    margin-top: 2.5rem;
    width: 100%;
    height: calc(100% - 2.5rem);
    &.main-container__fullscreen-mob-por {
      margin-top: 2.5rem;
      height: calc(100% - 2.5rem);
    }

    /* @screen xs {
      margin-top: 3rem;
      height: calc(100% - 2.5rem);
      &.main-container__fullscreen-mob-land {
        margin-top: calc(3rem);
        height: calc(100% - 2.5rem);
      }
    }

    @screen md {
      height: calc(100% - 3rem);
      &.main-container__fullscreen-tab {
        height: calc(100% - 3rem - 18px);
      }
    } */
  }
}
</style>
