var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    staticClass: "qr",
    staticStyle: { "z-index": "0" },
    on: {
      closeModal: function ($event) {
        return _vm.$emit("close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h2", [_vm._v("Appri la " + _vm._s(_vm.valueText))])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("qrcode-vue", {
              key: _vm.$route.fullPath,
              staticClass:
                "p-3 md:p-4 lg:p-5 bg-white w-fit mx-auto rounded-lg",
              attrs: {
                value: _vm.value,
                size: _vm.size,
                foreground: "#000000",
                background: "white",
                renderAs: "svg",
              },
            }),
            _c(
              "button",
              {
                staticClass:
                  "mx-auto mt-6 py-1 px-8 rounded rounded-full flex items-center",
                class: _vm.copied ? "bg-primary" : "bg-secondary",
                on: { click: _vm.copyToClipboard },
              },
              [
                _vm.copied
                  ? _c(
                      "p",
                      { staticClass: "text-gray-900 uppercase font-medium" },
                      [_vm._v("Copied")]
                    )
                  : _c(
                      "svg",
                      {
                        staticClass: "w-6 h-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          "stroke-width": "2.2",
                          stroke: "black",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244",
                          },
                        }),
                      ]
                    ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }