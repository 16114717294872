<template>
  <div
    @click="closeMenu"
    class="chat-wrapper"
    :class="{ 'chat-is-open': isOpen, fullscreen: isInFullscreen }"
    :style="
      keyboardHeight ? `height: calc(100% - 2.5rem - ${keyboardHeight})` : null
    "
  >
    <ChatBtn
      :disabled="!isReady"
      @change-chat-visibility="chatVisibilityHandler"
      class="chat-btn"
      :isOpen="isOpen"
      :screenSize="screenSize"
    />
    <!-- :class="{ desk: screenSize === 'desk', fullscreen: isInFullscreen }" -->
    <!-- v-if="$route.name === 'SlotsBar' || ($route.name === 'Bar' && !isInGame)" -->
    <div class="chat-container" v-if="isReady && isOpen">
      <div class="chat-title">
        <SectionTitle title="chat" class="w-full py-2" />
        <button
          @click.prevent="chatVisibilityHandler"
          class="close-chat-btn"
          v-if="screenSize === 'desk' || (screenSize !== 'desk' && isOpen)"
        >
          &times;
        </button>
      </div>

      <div class="chat">
        <div class="chat__header">
          <button
            class="tab"
            :class="{ current: currentTab === 'geral' }"
            @click.prevent="
              currentTab !== 'geral' ? (currentTab = 'geral') : null
            "
          >
            TUTTI
          </button>
          <button
            v-if="hasBarRouteParams"
            class="tab min-w-0"
            :class="{ current: currentTab === 'bar' }"
            @click.prevent="currentTab !== 'bar' ? (currentTab = 'bar') : null"
          >
            <div class="rounded-full gradient-blue relative">
              <img
                class="h-8 w-8 rounded-full object-cover"
                :style="
                  image === '/img/BAR_2.png'
                    ? 'filter: invert(1); padding: 0 0.15rem'
                    : ''
                "
                :src="image"
              />
              <div class="status-circle container gradient-blue">
                <div
                  class="status-circle content"
                  :style="
                    status === 'on'
                      ? 'background-color: #44b90f'
                      : 'background-color: red'
                  "
                ></div>
              </div>
            </div>
            <span class="w-fit max-w-4/5 truncate">
              {{ visibleRoomName }}
            </span>
          </button>
        </div>

        <keep-alive>
          <component
            class="chat__body"
            @change-room-status="changeRoomStatusHandler"
            :is="chatComp"
            :key="currentTab"
            :keyboardHeight="keyboardHeight"
            :room="
              this.currentTab === 'bar' && hasBarRouteParams
                ? roomName
                : geralChatName
            "
          />
        </keep-alive>
      </div>
    </div>
    <p v-else-if="!isReady && isOpen">Caricando...</p>
  </div>
</template>

<script>
import ChatBtn from "@/chat/components/ChatBtn.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import ArrowLeft from "@/components/utils/ArrowLeft.vue";
import RoundedBtn from "@/chat/components/buttons/RoundedBtn.vue";
import { getBarImage, getBarInfo } from "@/api/barsRepository";
import ChatBar from "@/chat/components/ChatBar.vue";
import { EventDispatcher } from "@amcharts/amcharts5/.internal/core/util/EventDispatcher";

export default {
  name: "Chat",

  props: ["isInFullscreen", "isInGame", "isSearchPopUpOpen", "keyboardHeight"],

  data() {
    return {
      status: "on",
      isOpen: false,
      currentTab: "bar",
      image: "/img/BAR_2.png",
      roomName: null,
      visibleRoomName: null,
      geralChatName: process.env.VUE_APP_CHAT_GERAL_ROOM_NAME,
      chatComp: "ChatBar",
      screenSize: "mob",
    };
  },

  components: {
    ChatBtn,
    SectionTitle,
    ArrowLeft,
    RoundedBtn,
    ChatBar,
  },

  watch: {
    "$route.params.barName": {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.roomName = this.$route.params.barName;
          getBarInfo(this.roomName)
            .then((res) => {
              this.visibleRoomName = res.data.roomVisibleName;
            })
            .catch((err) => {
              this.visibleRoomName = this.roomName;
            });
          getBarImage(this.roomName).then((res) => {
            if (res.data) {
              this.image = res.data;
            }
          });
        }
      },
    },

    isSearchPopUpOpen: function (val) {
      if (val === true) this.isOpen = false;
    },

    keyboardHeight: function (val) {
      if (val === 0) {
        document.body.removeEventListener(
          "touchmove",
          this.preventDefaultScroll
        );
      } else {
        document.body.addEventListener("touchmove", this.preventDefaultScroll, {
          passive: false,
        });
      }
      console.log(val);
    },
  },

  computed: {
    hasUserInfo() {
      if (this.$store.getters["userInfo/getUser"]) return true;
      return false;
    },

    hasBarRouteParams() {
      if (this.$route.params.barName) {
        return true;
      }
      return false;
    },

    isReady() {
      if (this.hasUserInfo && this.hasBarRouteParams) {
        this.currentTab = "bar";
        return true;
      } else if (this.hasUserInfo && !this.hasBarRouteParams) {
        this.currentTab = "geral";
        return true;
      }
      return false;
    },
  },

  created() {
    this.$root.$on("openChat", () => {
      this.isOpen = true;
    });
    this.$root.$on("closeChat", () => {
      this.isOpen = false;
    });
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    // if (this.room) {
    //   getBarImage(this.room).then((res) => {
    //     if (res.data) {
    //       this.image = res.data;
    //     }
    //   });
    // }
  },

  methods: {
    preventDefaultScroll(ev) {
      // Get the target element of the touch event
      const targetElement = ev.target;

      // Check if the touch event originated within the excluded div
      if (targetElement.closest(".messages-wrapper")) {
        return; // Allow default scrolling for the excluded div
      }

      ev.preventDefault();
    },
    closeMenu() {
      this.$root.$emit("closeMenu");
    },
    changeRoomStatusHandler(val) {
      this.status = val;
    },
    checkScreenSize() {
      if (window.innerWidth >= 1024) {
        this.screenSize = "desk";
      } else {
        this.screenSize = "mob";
      }
    },
    newMessagesClickHandler() {
      this.scrollDown = true;
      setTimeout(() => {
        this.scrollDown = false;
        this.moreMessages = false;
      }, 200);
    },

    moreMessagesHandler() {
      this.moreMessages = true;
      setTimeout(() => {
        this.moreMessages = false;
      }, 10000);
    },

    chatVisibilityHandler() {
      this.isOpen = !this.isOpen;
      this.$root.$emit("fullscreenBtnVisibility", !this.isOpen);
      if (!this.isOpen) {
        this.$root.$emit("closeMenu");
      }
    },
  },

  destroyed() {
    console.log("destroyed");
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style lang="postcss" scoped>
.status-circle {
  border-radius: 50%;

  &.container {
    width: 9px;
    height: 9px;
    bottom: 0;
    right: 1px;
    @apply flex items-center justify-center absolute;
  }

  &.content {
    width: 7px;
    height: 7px;
    right: 1px;
    @apply absolute;
  }
}
.gradient-blue {
  background: rgb(5, 6, 41);
  background: -moz-linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
}

.chat-wrapper {
  /* z-index: 1; */
  /* @apply fixed; */

  &.chat-is-open {
    /* margin-top: 2.5rem;
    height: calc(100% - 2.5rem);
    @apply w-full; */

    /* @screen lg {
      height: 600px !important;
      width: 450px;
      bottom: 0;
      right: 0;
    } */
  }

  &.fullscreen {
    /* margin-top: 16px; */
    /* margin-top: calc(3rem + 18px);
    /* height: calc(100% - 2.5rem - 16px - 18px);
    height: calc(100% - 2.5rem - 62px);
    /* height: calc(100% - 2.5rem - 16px);*/
  }
}

.chat-container {
  height: calc(100% - 2.5rem);
  @apply flex flex-col fixed right-0 w-full;

  @screen lg {
    height: 600px !important;
    width: 450px;
    @apply bottom-0;
  }

  .chat-title {
    background: rgb(5, 6, 41);
    @apply w-full h-fit flex;

    .close-chat-btn {
      background: rgb(21, 60, 115);
      @apply text-3xl px-3 pb-1;
    }
  }

  .chat {
    background-color: #222222;
    background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23191919' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");

    background-size: 200px 200px;
    background-repeat: repeat;
    @apply w-full flex-1 flex flex-col min-h-0;

    .chat__header {
      background-color: #222222;
      @apply flex justify-center relative;
      .tab {
        @apply flex-1 uppercase text-lg font-semibold tracking-wide h-10 flex items-center gap-x-2 justify-center bg-gray-600 truncate;

        &.current {
          background: linear-gradient(140deg, #225b8a 23.81%, #49b9d0 73.15%);
          /* @apply text-gray-900; */
        }

        &.current > * {
          @apply text-gray-900;
        }
      }
    }
    .chat__body {
      @apply flex flex-col flex-1 min-h-0 border-2 border-gray-700;
    }
  }
}

.chat-btn {
  /* z-index: 1;
  top: calc(2.5rem + 0.5rem);
  @apply fixed left-14; */

  /* &.desk {
    z-index: 1;
    top: 94%;
    @apply fixed left-14;

    @screen lg {
      margin-left: auto;
      @apply w-fit right-4;
    }
  } */

  /* &.fullscreen {
    top: calc(2.5rem + 18px);

  } */
}
</style>
