import Cookies from "js-cookie";
import userRepository from "../../api/userRepository";

const state = () => ({
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  userId: null,
  externalUserId: null,
  numContract: null,
  deviceId: null,
  sessionId: null,
});

// getters
const getters = {
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getDeviceId: (state) => state.deviceId,
  getIsAuthenticated: (state) => state.isAuthenticated,
  getUserId: (state) => state.userId,
  getNumContract: (state) => state.numContract,
  //    getRole: (state) => state.role,
  //    getRoomId: (state) => state.roomId,
};

// actions
const actions = {
  updateAccessToken({ commit }, accessToken) {
    commit("mutateAccessToken", accessToken);
  },

  updateRefreshToken({ commit }, refreshToken) {
    commit("setRefreshToken", refreshToken);
  },

  updateDeviceId({ commit }, deviceId) {
    commit("mutateDeviceId", deviceId);
  },

  updateIsAuthenticated({ commit, state }, isAuthenticated) {
    commit("setIsAuthenticated", isAuthenticated);
    userRepository.trackVisit(state.userId).then(() => {
      console.log("tracked visit");
    });
  },

  updateUserId({ commit }, userId) {
    commit("mutateUserId", userId);
  },

  updateExternalUserId({ commit }, externalUserId) {
    commit("mutateExternalUserId", externalUserId);
  },

  //    updateRole({ commit }, role) {
  //       commit("mutateRole", role);
  //    },

  //    updateRoomId({ commit }, roomId) {
  //       commit("mutateRoomId", roomId);
  //    },
  setUpAuth({commit}, auth) {
    commit("mutateAccessToken", auth.accessToken);
    commit("setRefreshToken", auth.refreshToken);
    commit("mutateUserId", auth.userId);
    commit("mutateExternalUserId", auth.externalUserId);
    commit("setIsAuthenticated", auth.isAuthenticated);
    commit("mutateNumContract", auth.numContract);
    commit("setSessionId", auth.sessionId);
    commit("mutateDeviceId", auth.deviceId);
  },

  cleanUpAuth({ commit }) {
    Cookies.remove("VIRTUAL_ROOMS_PLAYER_AUTH_COOKIE");
    commit("mutateAccessToken", null);
    commit("setRefreshToken", null);
    commit("mutateUserId", null);
    commit("mutateExternalUserId", null);
    commit("setIsAuthenticated", null);
    commit("mutateNumContract", null);
    commit("setSessionId", null)
  },
};

// mutations
const mutations = {
  mutateAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },

  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },

  mutateDeviceId(state, deviceId) {
    state.deviceId = deviceId;
  },

  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  mutateUserId(state, userId) {
    state.userId = userId;
  },

  mutateExternalUserId(state, externalUserId) {
    state.externalUserId = externalUserId;
  },

  mutateNumContract(state, numContract) {
    state.numContract = numContract;
  },

  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  }

  //    mutateRole(state, role) {
  //       state.role = role;
  //    },
  //    mutateRoomId(state, roomId) {
  //       state.roomId = roomId;
  //    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
