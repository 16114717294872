import httpClient from "@/helpers/httpClient";
import axios from "axios";

let refreshTokenResponse;

export function login(body) {
  return httpClient().post("auth", body);
}

export function loginFromSkin(token, contractNumber, skinId, polling) {
  return httpClient({
    maxRedirects: 0,
    headers: {
      "x-polling": polling,
      "x-skin-credentials": false,
    },
  }).post(`/auth/skin`, { token, contractNumber, skinId });
}

export function loginFromSkinWithCredentials(data) {

  const loginField = data.loginField;
  const password = data.password;
  const polling = data.polling;
  const skin = data.skin;
  const sessionId = data.sessionId;
  const skinId = data.skinId;

  let payload;

  if (loginField && password) {
    payload = {loginField, password};
    if(skin){
      payload.skinId = skin;
    }
  }

  if (sessionId && skinId) {
    payload = {token: sessionId, skinId};
  }

  return httpClient({
    maxRedirects: 0,
    headers: { "x-polling": polling, "x-skin-credentials": true },
  }).post(`/auth/skin`, payload);
}

export function authFromPwa(token, deviceId) {
  return axios.post(
    `${process.env.VUE_APP_BACKEND_API_URL}/auth/pwa`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Device-Id": deviceId,
      },
    }
  );
}

export function signupFromSkin(token, contractNumber, skinId) {
  return httpClient().post("auth/signup/skin", {
    token,
    contractNumber,
    skinId,
  });
}

export function resetPassword(body) {
  return httpClient().post("email/forgot-password", body);
}

export function newPassword(userId, token, body) {
  return httpClient({
    headers: { "x-reset-token": token },
  }).put(`/account/${userId}/password`, body);
}

export function refreshToken(body) {
  if (!refreshTokenResponse) {
    refreshTokenResponse = axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/refresh-token`,
      body
    );
  }
  return refreshTokenResponse;
}
