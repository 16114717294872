<script>
export default {
  name: "minWidthMixin560",

  data() {
    return {
      minWidth560: window.innerWidth >= 560,
    };
  },

  methods: {
    startEventMW560() {
      window.addEventListener("resize", this.defineMinWidth560);
    },

    defineMinWidth560() {
      this.minWidth560 = window.innerWidth >= 560;
    },
  },

  created() {
    this.startEventMW560();
  },

  destroyed() {
    window.removeEventListener("resize", this.defineMinWidth560);
  },
};
</script>
<style lang="postcss"></style>
