export default {
	'Lazio': [
		{
			name: 'Bar Gioran',
			totalPrize: '902357',
			numberGames: 13,
			image: '/img/bars/gioran.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 41.9456772,
				lng: 12.5194714
			},
			address: "Via Gioran",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/gioran/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/gioran/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/gioran/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar del Sol',
			totalPrize: '702357',
			numberGames: 13,
			image: '/img/bars/bar-xpto-1.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 41.9456772,
				lng: 12.5194714
			},
			address: "Via Gioran",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/gioran/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/gioran/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/gioran/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Trentotto',
			totalPrize: '524357',
			numberGames: 13,
			image: '/img/bars/bar-xpto-2.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 41.9456772,
				lng: 12.5194714
			},
			address: "Via Gioran",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/gioran/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/gioran/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/gioran/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Capanelle',
			totalPrize: '387954',
			numberGames: 13,
			image: '/img/bars/capanelle.jpg',
			in: 235168,
			out: 218395,
			jackpot: 12398,
			coordinates: {
				lat: 41.836814588295915,
				lng: 12.58417958294624
			},
			address: "Via Capanelle",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_money_storm.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 1,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_optimus_prize.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					reserved: '/img/glimpse/cabinets/VLT/vlt_riservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 4,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/capanelle/green.jpg',
					selected: false
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/capanelle/red.jpg',
					selected: true
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/capanelle/blue.jpg',
					selected: false
				}
			]

		},
		{
			name: 'Bar Giulia',
			totalPrize: '342168',
			numberGames: 13,
			image: '/img/bars/giulia.jpg',
			in: 187982,
			out: 178049,
			jackpot: 12398,
			coordinates: {
				lat: 41.8992964,
				lng: 12.4654786
			},
			address: "Via Giulia",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_wild_stars.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 5,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_money_storm.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 1,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 4,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/giulia/green.jpg',
					selected: false
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/giulia/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/giulia/blue.jpg',
					selected: true
				}
			]
		}
	],
	'Veneto': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Abruzzo': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 42.349850,
				lng: 13.399509
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 44.5557159,
				lng: 10.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Aosta Valley': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 44.734955,
				lng: 8.31307
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 10.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	"Valle d'Aosta": [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.738888,
				lng: 12.426187
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 12.7353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Puglia': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 42.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 37.5557159,
				lng: 14.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Basilicata': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 38.8829592,
				lng: 16.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 10.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Calabria': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 10.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Campania': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Emilia-Romagna': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Friuli Venezia Giulia': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Liguria': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Lombardia': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Marche': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Molise': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Piemonte': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Sardegna': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Sicilia': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Trentino-Alto Adige': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Toscana': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],

	'Umbria': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Corse': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Malta': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
	],

	'San Marino': [
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/tucano.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 42.6557159,
				lng: 10.353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	],
	'Vatican City': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/bars/telliera.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 42.0108,
				lng: 12.023
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					reserved: '/img/glimpse/cabinets/glamour/glamour_reservato.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},

	],
}
