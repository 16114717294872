<template>
  <Modal @closeModal="$emit('close')" class="qr" style="z-index: 0">
    <template v-slot:header>
      <h2>Appri la {{ valueText }}</h2>
    </template>
    <template v-slot:body>
      <qrcode-vue
        :key="$route.fullPath"
        :value="value"
        :size="size"
        class="p-3 md:p-4 lg:p-5 bg-white w-fit mx-auto rounded-lg"
        foreground="#000000"
        background="white"
        renderAs="svg"
      ></qrcode-vue>
      <button
        @click="copyToClipboard"
        class="mx-auto mt-6 py-1 px-8 rounded rounded-full flex items-center"
        :class="
          copied
            ? 'bg-primary'
            : 'bg-secondary'
        "
      >
        <p class="text-gray-900 uppercase font-medium" v-if="copied">Copied</p>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.2"
          stroke="black"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
          />
        </svg>
      </button>
    </template>
  </Modal>

  <!-- <div id="modal-template">
      <transition name="modal">
         <div class="modal-mask">
            <div class="modal-wrapper">
               <div class="modal-container">
                  <div class="modal-header">
                     <slot name="header">
                        <h3 class="mb-6 md:text-xl lg:text-2xl text-center">
                           Appri la {{ valueText }}
                        </h3>
                     </slot>
                  </div>

                  <div class="modal-body" @click="$emit('close')">
                     <slot name="body">
                        <qrcode-vue
                           :key="$route.fullPath"
                           :value="value"
                           :size="size"
                           class="p-3 md:p-4 lg:p-5 bg-white"
                           foreground="#000000"
                           background="white"
                           renderAs="svg"
                        ></qrcode-vue>
                     </slot>
                  </div>

                  <div class="modal-footer">
                     <slot name="footer">
                        <button
                           class="modal-default-button md:text-xl lg:text-2xl"
                           @click="$emit('close')"
                        >
                           OK
                        </button>
                     </slot>
                  </div>
               </div>
            </div>
         </div>
      </transition>
   </div> -->
</template>

<script>
import QrcodeVue from "qrcode.vue";
import minWidthMixin560 from "@/mixins/minWidthMixin560";
import Modal from "./utils/Modal.vue";

export default {
  name: "QRCode",

  mixins: [minWidthMixin560],

  components: { Modal, QrcodeVue },

  data() {
    return {
      copied: false,
      value:
        window.location.href.split(window.location.origin + "/")[1] ===
        "settings"
          ? window.location.origin
          : window.location.href,
      valueText: " ",
      size: 150,
      minWidth850: window.innerWidth >= 850,
    };
  },

  watch: {
    minWidth560: "QRCodeSize",
    minWidth850: "QRCodeSize",
  },

  created() {
    const barName = this.$route.params.barName;
    if (barName) {
      this.valueText = "virtual room " + barName;
    } else {
      this.valueText = "mappa";
    }

    this.startEventMW850();
    this.QRCodeSize();
  },

  methods: {
    async copyToClipboard() {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        navigator.clipboard.writeText(this.value);
      } else {
        const el = document.createElement("div");
        let input = document.createElement("input");
        input.value = this.value;
        el.appendChild(input);
        input.focus();
        input.select();
        document.execCommand("copy");
        el.removeChild(input);
      }
      this.copied = true;
      setTimeout(() => (this.copied = false), 2000);
    },
    QRCodeSize() {
      if (window.innerWidth < 850) {
        this.size = 150;
      } else {
        this.size = 350;
      }
    },

    startEventMW850() {
      window.addEventListener("resize", this.defineMinWidth850);
    },

    defineMinWidth850() {
      this.minWidth850 = window.innerWidth >= 850;
    },
  },

  destroyed() {
    window.removeEventListener("resize", this.defineMinWidth850);
  },
};
</script>
