import httpClient from "@/helpers/httpClient";

const basePath = "user-settings/user";

export default {
   getSettings(id) {
      return httpClient().get(`${basePath}/${id}`);
   },
   updateSettings(id, body) {
      return httpClient().put(`${basePath}/${id}`, body);
   },
};
