import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/",
    name: "SlotsBar",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "slots-bar" */ "../views/Glimpse.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
  {
    path: "/tokens",
    name: "TokensMovements",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "tokens" */ "../views/TokensMovements.vue"),
  },
  {
    path: "/bar/:barName",
    name: "Bar",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "client-bar-popup" */ "../components/ClientBarPopup.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
      ),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "error" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const params = new URLSearchParams(window.location.search);

  let isAuthenticated =
    !!store.getters["auth/getRefreshToken"] ||
    !!store.getters["auth/getAccessToken"];

  let query = {
    nextUrl: to.fullPath,
  };

  if (params.get("registrationUrl")) {
    query = {
      ...query,
      registrationUrl: params.get("registrationUrl"),
    };
  }

  if (params.get("roomName")) {
    query = {
      ...query,
      roomName: params.get("roomName"),
    };
  }

  if (!requiresAuth) {
    console.log("to.name", to.name);
    if (isAuthenticated && to.name == "Login") {
      return next({
        name: "SlotsBar",
      });
    }
    return next();
  }

  if (!isAuthenticated) {
    return next({
      name: "Login",
      query,
    });
  }

  return next();
});

export default router;
