var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "wallet-display" } }, [
    _c(
      "button",
      {
        staticClass: "button grid grid-cols-3 items-center",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.creditClick.apply(null, arguments)
          },
        },
      },
      [
        !_vm.hideTitles
          ? _c("p", { staticClass: "w-fit text-sm text-gray-400" }, [
              _vm._v("Wallet"),
            ])
          : _vm._e(),
        _c(
          "span",
          {
            staticClass: "col-span-2",
            class: { "col-span-full": _vm.hideTokens },
          },
          [
            _c("p", { staticClass: "text-base w-fit ml-auto" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.displayCredits ? _vm.formatCredit : _vm.hideCredit
                  ) +
                  " "
              ),
            ]),
          ]
        ),
      ]
    ),
    !_vm.hideTokens
      ? _c(
          "div",
          {
            ref: "tooltip-label",
            staticClass: "button grid grid-cols-3 items-center",
            on: {
              mousemove: _vm.handleTooltip,
              mouseleave: _vm.hideTooltip,
              click: function ($event) {
                $event.preventDefault()
                return _vm.goToTokensView.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { staticClass: "w-fit text-sm text-gray-400" }, [
              _vm._v("Tokens"),
            ]),
            _c(
              "span",
              { staticClass: "flex justify-end col-span-2 relative" },
              [
                _c(
                  "span",
                  {
                    staticClass: "text-base",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleTooltip.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.tokens) + " ")]
                ),
                _c("img", {
                  staticClass: "w-4 h-4 ml-1 my-auto -mr-1.5",
                  attrs: {
                    src: "/img/vrtoken.png",
                    alt: "Virtual Rooms token icon",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleTooltip.apply(null, arguments)
                    },
                  },
                }),
                _c("div", { ref: "tooltip", staticClass: "tooltip" }, [
                  _vm._v(
                    " Puoi acquistare fantastiche funzionalità con il nostro incredibile token. "
                  ),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }