import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import gamesList from "@/gamesList";
import barsPerRegion from "@/barsPerRegion";
// Global Components
import CustomImage from "@/components/utils/CustomImage";
import store from "@/store";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper-bundle.min.css";
import fullscreen from "vue-fullscreen";
import VueGtag from "vue-gtag";
import { VueHammer } from "vue2-hammer";
import "../src/assets/css/style.pcss";
import { DateTime } from "luxon";

Vue.use(VueHammer);
Vue.use(fullscreen);
SwiperCore.use([Navigation]);

Vue.component("custom-image", CustomImage);

Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.productionTip = false;

Vue.filter("money", function (value) {
  if (parseInt(value) !== 0 && !value) {
    return "";
  }
  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  }).format(value.toFixed(2));
});

Vue.filter("dateTime", (value) => {
  let lang = "it-IT";
  if (!value) return "-";
  return DateTime.fromISO(value).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
    {
      locale: lang,
    }
  );
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    },
  },
  router
);

// Vue.prototype.$axios = httpClient;

new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      barsPerRegion: {},
    };
  },

  created() {
    this.barsPerRegion = barsPerRegion;
  },
}).$mount("#app");
